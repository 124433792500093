body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100vh;

    background-color: #483828;
    background-image:  linear-gradient(135deg, #584840 25%, transparent 25%), linear-gradient(225deg, #584840 25%, transparent 25%), linear-gradient(45deg, #584840 25%, transparent 25%), linear-gradient(315deg, #584840 25%, #483828 25%);
    background-position:  32px 0, 32px 0, 0 0, 0 0;
    background-size: 64px 64px;
    background-repeat: repeat;

    font-family: 'Josefin Sans', sans-serif;
}

#GameBoard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 75vh;

    padding: 2rem;
    background-color: #A89060FF;

    border: #382818 solid 1rem;
}

#GameBoard > * {
    margin: 0 1rem;

    filter: drop-shadow(0.25rem 0.25rem 0.5rem #483828);
}

#Tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 20vh;
    height: 100%;
}

.Tool {
    margin: 0.5rem 0;

    aspect-ratio: 35 / 50;
    height: 50%;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#Reset {
    margin-top: 1rem;

    aspect-ratio: 2 / 1;
    width: 100%;

    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#Tiles {
    display: grid;
    grid: repeat(10, 1fr) / repeat(13, 1fr);

    aspect-ratio: 13 / 10;

    height: 100%;

    filter: drop-shadow(0 0 1rem #483828);

    border: #483828 solid 0.5rem;
}

.DiggingTile{
    aspect-ratio: 1 / 1;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#Dinosaur {
    aspect-ratio: 35 / 60;

    height: 100%;
    width: 100%;

    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
}

#DinosaurPopup {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    position: absolute;
    z-index: 10;

    font-size: 2rem;

    padding: 1rem;
    border-radius: 1rem;
    filter: drop-shadow(0.25rem 0.25rem 0.5rem #483828);

    background-color: rgb(168, 144, 96);
}

#DinosaurPopupImage {
    aspect-ratio: 1 / 1;
    height: 20vh;

    margin-right: 1rem;

    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
}

#Durability {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    color: white;
    font-size: 3rem;

    margin-bottom: 1rem;

    padding: 0.5rem;

    background-color: rgb(168, 144, 96);
}

.InactiveTool {
    filter: saturate(0);
}
